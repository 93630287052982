<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
<!--        <vuexy-logo />-->

        <h2 class="brand-text text-primary ml-1">
      Đổi mật khẩu
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              :src="imgUrl"
              fluid
              alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
          >
            Đặt lại mật khẩu 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Mật khẩu mới của bạn phải khác với các mật khẩu đã sử dụng trước đó
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
                method="POST"
                class="auth-reset-password-form mt-2"
                @submit.prevent="validationForm"
            >
              <!-- password -->
              <b-form-group
                  label="Mật khẩu cũ"
                  label-for="reset-password-old"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Mật khẩu cũ"
                    rules="required|passwordMin"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input  @change="validateOldPassword(passwordDTO.oldPassword)"
                                   id="reset-password-old"
                                   v-model="passwordDTO.oldPassword"
                                   :type="passwordFieldType"
                                   :state="errors.length > 0 ? false:null"
                                   class="form-control-merge"
                                   name="reset-password-new"
                                   placeholder="Nhập mật khẩu cũ"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                  label="Mật khẩu mới"
                  label-for="reset-password-new"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Mật khẩu mới"
                    vid="Password"
                    rules="required|passwordMin"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input @change="validateNewPassword(passwordDTO.newPassword)"
                                  id="reset-password-new"
                                  v-model="passwordDTO.newPassword"
                                  :type="password1FieldType"
                                  :state="errors.length > 0 ? false:null"
                                  class="form-control-merge"
                                  name="reset-password-new"
                                  placeholder="Nhập mật khẩu mới"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                  label-for="reset-password-confirm"
                  label="Nhập lại mật khẩu mới"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Nhập lại mật khẩu mới"
                    rules="required|confirmed:Password"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input @change="validateCPassword(cPassword)"
                                  id="reset-password-confirm"
                                  v-model="cPassword"
                                  :type="password2FieldType"
                                  class="form-control-merge"
                                  :state="errors.length > 0 ? false:null"
                                  name="reset-password-confirm"
                                  placeholder="Nhập lại mật khẩu mới"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                  block
                  type="submit"
                  variant="primary"
              >
                Đặt lại mật khẩu
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v1'}">
              <feather-icon icon="ChevronLeftIcon" /> Quay lại
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    // VuexyLogo,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      passwordDTO: {},
      userEmail: '',
      cPassword: '',
      password: '',
      passwordOld: '',
      // validation
      required,

      // Toggle Password
      passwordFieldType: 'password',
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },

  },
  methods: {
    validateNewPassword(val) {
      this.passwordDTO.newPassword = val.trim()
    },
    validateCPassword(val) {
      this.cPassword = val.trim()
    },
    validateOldPassword(val) {
      this.passwordDTO.oldPassword = val.trim()
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$authUser.post('api/v1/user/change-password', this.passwordDTO).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Đổi mật khẩu thành công !',
              },
            })
            localStorage.clear()
            this.$router.push('/login')
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-auth.scss';
</style>
